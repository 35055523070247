import React from 'react';
import { Route, NavLink } from 'react-router-dom';

const projectCat = [
  {
    slug: 'website',
    name: 'website'
  },
  {
    slug: 'webapp',
    name: 'web app'
  },
  {
    slug: 'graphics',
    name: 'graphics'
  }
];
const projectStatus = ['ongoing/maintaining','completed','coming soon','archive/hidden'];
const projectData = [
  {
    slug: 'interstyleglass',
    name: 'InterstyleGlass.com',
    cat: 0,
    description:
      'Interstyleglass.com is the main company website I currently maintain for Interstyle. The original main website was www.interstyle.ca using HTML. I rebuilt the website using WordPress with WooCommerce, custom child theme, custom post type, and custom shortcode plugin.',
    img: 'interstyleglass-2018-11-10-20_13_17',
    year: 2016,
    status: 0,
    url: 'https://interstyleglass.com'
  },
  {
    slug: 'vetrotile',
    name: 'Vetrotile.com',
    cat: 0,
    description:
      'Vetrotile.com is one of the websites I currently maintain. I rebuilt the website using WordPress with WooCommerce, custom child theme, and custom shortcode plugin.',
    img: 'vetrotile-2018-11-10-20_14_42',
    year: 2017,
    status: 0,
    url: 'https://vetrotile.com'
  },
  {
    slug: 'reflectionsinglasstile',
    name: 'ReflectionsInGlassTile.com',
    cat: 0,
    description:
      'ReflectionsInGlassTile.com is one of the websites I currently maintain. I rebuilt the website using WordPress with WooCommerce, custom child theme, and custom shortcode plugin.',
    img: 'reflectionsinglasstile-2018-11-10-20_14_14',
    year: 2017,
    status: 0,
    url: 'https://www.reflectionsinglasstile.com'
  },
  {
    slug: 'aboutglasstile',
    name: 'AboutGlassTile.com',
    cat: 0,
    description:
      'AboutGlassTile.com is one of the websites I currently maintain. I rebuilt the website using WordPress and custom theme.',
    img: 'aboutglasstile-2018-11-10-20_15_22',
    year: 2016,
    status: 0,
    url: 'https://aboutglasstile.com'
  },
  {
    slug: 'rodinlaw',
    name: 'RodinLawFirm.com',
    cat: 0,
    description: 'Rodin Law project was my first completed custom WordPress website project for Pace Creative as a contractor. I built the website based on a mockup provided. The website\'s custom theme was built using bootstrap framework. There are custom post types built for the client to update content easily in the future.',
    img: 'rodinlaw-2015',
    year: 2014,
    status: 1,
    url: 'https://www.rodinlawfirm.com'
  },
  {
    slug: 'jsjquerytutorial',
    name: 'JavaScript/JQuery Tutorial',
    cat: 0,
    description: 'After completing a JavaScript/jQuery tutorial website project for term 2 BCIT New Media Web Scripting 1, I was introduced to a CSS framework call Pure . I enjoy exploring and working with it. Therefore, I decided to redesign my tutorial site using the framework.',
    img: 'jenny-leung-javaScript_jquery_tutorial',
    year: 2014,
    status: 1,
    url: 'http://jenny-leung.com/js_tutorials/'
  },
  {
    slug: 'bootstrapframeworkminorupark',
    name: 'Minoru Park Bootstrap Site',
    cat: 0,
    description: 'A fictional Minoru Park information website. This web framework project was built using Bootstrap framework.',
    img: 'jenny-leung-minoru_park_bootstrap_framework_site',
    year: 2014,
    status: 1,
    url: 'http://jenny-leung.com/minoru_park/'
  },
  {
    slug: 'responsivemusictutorsite',
    name: 'Responsive Music Tutor Site',
    cat: 0,
    description: 'A fictional responsive music tutor\'s website. All basic and responsive styles are hand coded using CSS. I was introduced to musical instruments when I was in grade 4, where I learned playing recorder and ukulele. In grade 6, I joined the concert band and learned to play flute. In grade 7, I started taking private piano lessons and received a Royal Conservatory of Music certificate for grade 7 piano. Becoming a piano teacher was my goal. My passion for music guided me to create a fictional responsive website introducing me as a music tutor.',
    img: 'jenny-leung-responsive_site_music_tutor',
    year: 2014,
    status: 3,
    url: 'http://jenny-leung.com/responsive_site/'
  },
  {
    slug: 'vccjoomla',
    name: 'VCC Joomla Home Page',
    cat: 0,
    description: 'Redesigning the Vancouver Chinese Christian Short-Term Mission Training Centre home page is my first Joomla project. The original website, http://vccweb.globaltm.org/, was made with flash. The new site\'s animation effects are created using custom CSS code, the Traditional Chinese and Simplified Chinese are both available for the website, and a few custom database tables are displayed using a plug in. Thanks to http://tympanus.net/Tutorials/CircleHoverEffects/, I learn beautiful CSS effects. Black & White 2 is also a great free responsive Joomla theme provided by Ice Theme.',
    img: 'jenny-leung-vcc_homepage',
    year: 2014,
    status: 3,
    url: 'http://vcc.jenny-leung.com/'
  },
  {
    slug: 'simplewordpresstheme',
    name: 'Simple WordPress Theme',
    cat: 0,
    description: 'The Technology Impact on Education website is the first WordPress site I built. Simple Theme is also my first custom built WordPress Theme using the Underscore starter theme.',
    img: 'jenny-leung-simple_wp_theme',
    year: 2013,
    status: 3,
    url: 'http://jenny-leung.com/tech-impact-on-education/'
  },
  {
    slug: 'contractorquote',
    name: 'Contractor Quote',
    cat: 1,
    description: 'Quotation app for contractors.',
    img: 'placeholder',
    year: 2018,
    status: 2
  },
  {
    slug: 'interstyle:bannergenerator',
    name: 'Banner Generator',
    cat: 1,
    description: 'Rendering tool for Interstyle',
    img: 'placeholder',
    year: 2018,
    status: 2
  },
  {
    slug: 'accountingquote',
    name: 'Accounting Quote',
    cat: 1,
    description: 'Accounting quote is a web quotation application for accountants to generate a quote for their clients. I made this project for my father, who is a CPA. He gave me requirements and feedback for this project based on his experiences. This application is user friendly with cell phone. Users can enter data, view quotation, print and email directly from mobile device.',
    img: 'jenny-leung-accounting-quote-2018-11-23-23_42_34',
    year: 2018,
    status: 0,
    url: 'http://jenny-leung.com/accounting-quote/'
  },
  {
    slug: 'interstyle:glasstileconfigurator',
    name: 'Glass Tile Configurator',
    cat: 1,
    description: 'Glass tile configurator is a rendering and quotation web application. I built it for Interstyle to speed up quoting, graphic and production process. Sometimes update patterns, sizes, colours, or pricing.',
    img: 'interstyleglass-glasstile-configurator-2018-11-10-20_12_10',
    year: 2017,
    status: 0,
    url: 'https://interstyleglass.com/glasstile/configurator/'
  },
  {
    slug: 'interstyle:mosaicmaker',
    name: 'Mosaic Maker',
    cat: 1,
    description: 'Mosaic maker is a web application I built for Interstyle for speeding up quoting, graphic and production process of custom mosaics. This tool displays the custom mosaic for client approval and production reference.',
    img: 'interstyle-mosaic-maker-2018-11-11',
    year: 2018,
    status: 0
  },
  {
    slug: 'interstyle:colordb',
    name: 'Colour Database',
    cat: 1,
    description: 'Colour database was built for Interstyle to find identical or similar colours with different names in different product lines. Latest 240 colours also have LRV reference. There is table view and card view.',
    img: 'interstyle-color-database-2018-11-11',
    year: 2018,
    status: 0
  },
  {
    slug: 'colortonegenerator',
    name: 'Colour Tone Generator',
    cat: 1,
    description: 'I built colour tone generator for finding colour tones of the colour I like. Users can adjust the RGB values for the colour and the tone percentage for the contrast.',
    img: 'jenny-leung-javascript_color_tone_generator-2018-11-11-14_18_11',
    year: 2016,
    status: 1,
    url: 'http://jenny-leung.com/javascript_color_tone_generator/'
  },
  {
    slug: 'dressup',
    name: 'Dress Up',
    cat: 1,
    description: 'I enjoy playing dress up games online. Hence, I created this simple dress up game using JavaScript/jQuery, HTML, and CSS. The graphics are created using Illustrator.',
    img: 'jenny-leung-dressup',
    year: 2014,
    status: 1,
    url: 'http://jenny-leung.com/dressup/'
  },
  {
    slug: 'interstyle:firesidebanner',
    name: 'Fireside Mix Rendering',
    cat: 2,
    description: 'Rendering of mosaic Grazed Fireside mix for Interstyle\'s mosaic collection.',
    img: 'interstyle-grazed-fireside-rendering-banner',
    year: 2018,
    status: 1
  },
  {
    slug: 'interstyle:gallery',
    name: 'Interstyle social media images',
    cat: 2,
    description: 'Edit installation and tile photo colors for social media and e-newsletters.',
    img: 'interstyle-social_media-gallery',
    year: 2018,
    status: 0
  },
  {
    slug: 'weatheranimation',
    name: 'Weather Animation',
    cat: 2,
    description: 'A weather animation created using only HTML and CSS. After creating a cloud animation using images in the Web Animation course, I decided to create a weather animation. However, I often leave websites with large images that take a very long time to download. Hence, I decided to use SVG images. Just to make the animation more interesting, when the user holds down the mouse, there will be lightning.',
    img: 'jenny-leung-weather_animation',
    year: 2014,
    status: 1,
    url: 'http://jenny-leung.com/weather_animation/'
  },
  {
    slug: '3dleadalphabet',
    name: '3-D Lead Alphabet',
    cat: 2,
    description: 'Two versions of 3-D Lead Alphabet. One with a white background. One with a black background. The types are created by taking photos of letters made up of lead (for pencil). The images are then edited using Adobe Photoshop camera raw and masking techniques. The website is created using pure HTML and CSS; no JavaScript.',
    img: 'jenny-leung-3d_lead_alphabet',
    year: 2014,
    status: 1,
    url: 'http://jenny-leung.com/3d_lead_alphabet/'
  },
  {
    slug: 'pixelartparkanimation',
    name: 'Pixel Art Park Animation',
    cat: 2,
    description: 'I used to live near Minoru Park. This pixel art park animation is based on a photo I took there. The graphics are created using illustrator and the animation is done using only HTML and CSS.',
    img: 'jenny-leung-park_animation',
    year: 2014,
    status: 1,
    url: 'http://jenny-leung.com/park_animation/'
  },
  {
    slug: 'glasspartition',
    name: 'Glass Partition',
    cat: 2,
    description: 'Glass parition rendering made with Blender',
    img: 'placeholder',
    year: 2018,
    status: 2
  },
  {
    slug: 'wallpaper',
    name: 'Glass + Feather Wallpaper',
    cat: 2,
    description: 'Glass + Feather Wallpaper made with Blender',
    img: 'placeholder',
    year: 2018,
    status: 2
  },
  {
    slug: 'portrait',
    name: 'My Portrait',
    cat: 2,
    description: 'Vector cartoon portrait of me.',
    img: 'placeholder',
    year: 2018,
    status: 2
  }
];


const MyProjectList = ({ match, projectCategory }) => {  
  let projectListNav;
  let projectListCat;
  if (projectCategory === null) {
    projectListNav = projectData.map((project, id) => {
      return (
        (project.status !== 2 && project.status !== 3) &&
          (<li key={id}>
            <NavLink to={`${match.url}/${projectCat[project.cat].slug}/${project.slug}`} className="App-nav__link" activeClassName="active">{project.name}</NavLink>
          </li>)
      );
    });
  } else {
    const filteredProjectData = projectData.reduce((newList, project) => {
      if (project.cat === projectCategory && (project.status !== 2 && project.status !== 3)) {
        newList.push(project);
      }
      return newList;
    },[]);
    projectListNav = filteredProjectData.map((project, id) => {
      return (
        <li key={id}>
          <NavLink to={`${match.url}/${project.slug}`} className="App-nav__link" activeClassName="active">{project.name}</NavLink>
        </li>
      );
    });
    projectListCat = filteredProjectData.map((project, id) => {
      return (
        <CurrentProject key={id} slug={project.slug} />
      );
    });
  }

  return (
    <section className="App-section App-content">
      <h2>{ projectCategory === null ? 'all projects' : projectCat[projectCategory].name }</h2>
      <nav className="App-nav App-nav__breadcrumb">
        <ul className="App-nav__ul">
          {projectListNav}
        </ul>
      </nav>
      { projectCategory === null && <FeaturedProjects />}
      { projectCategory !== null && <Route exact path={`${match.path}`}
        render={() => (projectListCat)}
      />}
      
      <Route exact path={`${match.path}/:project`}
        render={({ match, props }) => (
          <CurrentProject slug={match.params.project} />
        )}
      />
    </section>
  );
};

const FeaturedProjects = () => {
  return (
    <>
      <h2>featured projects</h2>
      <CurrentProject slug="interstyle:glasstileconfigurator" />
      <CurrentProject slug="interstyleglass" />
    </>
  );
};
const CurrentProject = props => {
  const thisProject = projectData.find(project => project.slug === props.slug);
  const imgSrcset = '/img/300/' + thisProject.img+'.jpg 300w, '+'/img/380/'+thisProject.img+'.jpg 380w, '
  +'/img/450/'+thisProject.img+'.jpg 450w, '+ '/img/570/'+thisProject.img+'.jpg 570w';
  return (
    <div className="App-project">
      <figure className="App-project__img">
        <img srcSet={ imgSrcset }
            sizes="(max-width: 250px) 230px,(max-width: 400px) 380px,(max-width: 480px) 100vw,(max-width: 768px) 375px, 570px"
            src={ '/img/570/'+thisProject.img+'.jpg' } 
            alt={thisProject.description}/>
      </figure>
      <article className="App-project__detail">
        <h3 className="App-project__name">{thisProject.name}</h3>
        <p className="App-project__des">{thisProject.description}</p>
        <p className="App-project__year">{thisProject.year}</p>
        <p className="App-project__status">{projectStatus[thisProject.status]}</p>
        { thisProject.hasOwnProperty('url') && <p className="App-project__url"><a href={thisProject.url} target="new" className="App-link">{thisProject.url}</a></p> }
      </article>
    </div>
  );
};

export {CurrentProject, FeaturedProjects};
export default MyProjectList;