import React from 'react';
import { Route, NavLink } from 'react-router-dom';

import MyProjectList from './Projects';

const Portfolio = ({ match }) => {
  window.scrollTo(0,0);
  return (
    <>
      <main className="App-section App-content">
        <h1>portfolio</h1>
        <nav className="App-nav App-nav__breadcrumb">
          <ul className="App-nav__ul">
            <li>
              <NavLink exact to={`${match.url}`} className="App-nav__link" activeClassName="active">All</NavLink>
            </li>
            <li>
              <NavLink to={`${match.url}/website`} className="App-nav__link" activeClassName="active">Website</NavLink>
            </li>
            <li>
              <NavLink to={`${match.url}/webapp`} className="App-nav__link" activeClassName="active">Web App</NavLink>
            </li>
            <li>
              <NavLink to={`${match.url}/graphics`} className="App-nav__link" activeClassName="active">Graphics</NavLink>
            </li>
          </ul>
        </nav>
        <Route path={`${match.path}`} render={({ match: { path } }) => (
          <>
            <Route exact path={`${path}`} 
                    render={(props) => <MyProjectList {...props} projectCategory={null}/>} />
            <Route path={`${path}/website`} 
                    render={(props) => <MyProjectList {...props} projectCategory={0}/>} />
            <Route path={`${path}/webapp`} 
                    render={(props) => <MyProjectList {...props} projectCategory={1}/>} />
            <Route path={`${path}/graphics`} 
                    render={(props) => <MyProjectList {...props} projectCategory={2}/>} />
          </>
          )}
        />       
      </main>
      
    </>
  );
};
  
  export default Portfolio;