import React from 'react';
import jennyAtBcit from '../img/jenny-at-bcit-2014.jpg';

const About = () => {
  // window.scrollTo(0,0);
  return (
    <article className="App-section App-content App-content__about">
      <h2>about me</h2>
      <img src={jennyAtBcit} alt="Jenny at BCIT Downtown Campus 2014" className="App-img" />
      <p>Currently, I am working for Interstyle Ceramic and Glass Ltd as web developer and graphic designer. With working experince from Interstyle, I specialize in customizing WordPress (+WooCommerce) and building web app. I code my web projects with HTML5, CSS3, JavaScript/jQuery/React, and PHP.</p>

      <p>My tasks at Interstyle include the following: </p>
      <ul className="App-list">
        <li>rebuild and maintain multiple websites using WordPress with WooCommerce
      and using custom theme, post type, shortcode plugin to speed up loading.</li>
        <li>speed up quoting, graphic and production process by building configurator
      and mosaic maker web applications</li>
        <li>propose efficient, effective and economical solutions for feature requests on website and web applications</li>
        <li>update marketing materials using Photoshop, Illustrator and InDesign</li>
        <li>create renderings for design approval, e-newsletter, and social media</li>
        <li>set up and test e-newsletter with MailChimp</li>
      </ul>
       
      <p>Previously, I completed the New Media and Web Development at British Columbia Institute of Technology (BCIT) with over 90% in all web hand coding courses in 2014. Other skills I obtained from this program include Adobe Acrobat Pro, Photoshop, Illustrator, Premiere Pro, After Effects, Dreamweaver and Bridge. After graduating, I completed a WordPress project for Pace Creative as a contractor.</p>

      <p>Fluent in English, Cantonese, and Mandarin.</p>

      <p>During my leisure time, I like to watch volleyball games.</p>

      <a href="../JennyLeung_Resume.pdf" target="new" className="App-btn">resume</a>
      <a href="https://www.linkedin.com/in/jenny-leung-32b5538a" target="new" className="App-btn">LinkedIn profile</a>

      
    </article>
  );
};
  
export default About;