import React from 'react';
import About from './About';
import logo from '../img/jennyleung_logo-20181004.svg';
import { NavLink } from 'react-router-dom';
import {FeaturedProjects} from './Projects';
const Home = () => {
  // window.scrollTo(0,0);
  return (
    <>
      <section className="App-section App-content__home">
        <img src={logo} className="App-logo" alt="logo" />
        <h1 className="App-content__home__h1">Nice to meet you! I'm Jenny.</h1>
        <p>Welcome to my portfolio! I'm a web developer and designer.</p>
        <nav className="App-nav App-nav__breadcrumb">
          <ul className="App-nav__ul">
            <li>
              <NavLink to="/portfolio/website" className="App-nav__link" activeClassName="active">website</NavLink>
            </li>
            <li>
              <NavLink to="/portfolio/webapp" className="App-nav__link" activeClassName="active">web app</NavLink>
            </li>
            <li>
              <NavLink to="/portfolio/graphics" className="App-nav__link" activeClassName="active">graphics</NavLink>
            </li>
          </ul>
        </nav>
        <span className="App-arrow--down"></span>
        
      </section>
      <main className="App-section App-content">
        <FeaturedProjects />
        
        <NavLink to="/portfolio" className="App-btn">see more projects</NavLink>
      </main>
      <About />
    </>
  );
};
  
export default Home;