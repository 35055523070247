import React from 'react';

const Contact = () => {
  window.scrollTo(0,0);
  return (
    <section className="App-section App-content">
      <h2>contact me</h2>
      <p>Email: <a href="mailto:jenny@jenny-leung.com" className="App-link">jenny@jenny-leung.com</a></p>
      <p>Phone: <a href="tel:7788910261" className="App-link">(778) 891-0261</a></p>
      
      <p>LinkedIn: <a href="http://ca.linkedin.com/pub/jenny-leung/8a/553/32b" className="App-link">Profile</a></p>
      <p>Twitter: <a href="https://twitter.com/ManFongJenny" className="App-link">Profile</a></p>

      <p>Location: Richmond, BC, Canada</p>
    </section>
  );
};

export default Contact;