import React, { Component } from 'react';
// import {withRouter} from 'react-router-dom';
import logo from './img/jennyleung_logo-20181004.svg';
import './App.scss';

import { BrowserRouter, Switch, Route, NavLink, Redirect } from 'react-router-dom';

import Home from './Components/Home';
import Portfolio from './Components/Portfolio';

import About from './Components/About';
import Contact from './Components/Contact';

// import NotFound from './Components/NotFound';

class App extends Component {
  getYear() {
    return new Date().getFullYear();
  }

  render() {
    return (
      <BrowserRouter>
        <div className="App">
          <header className="App-section App-header">
            <nav className="App-nav App-nav__primary">
              <ul className="App-nav__ul">
                <li>
                  <NavLink exact to="/" className="App-nav__link App-nav__link--home" activeClassName="active"><img src={logo} className="App-logo" alt="logo" /></NavLink>
                </li>
                <li>
                  <NavLink to="/portfolio" className="App-nav__link App-nav__link--dropdown" activeClassName="active">portfolio</NavLink>
                  <ul className="App-nav__ul">
                    <li>
                      <NavLink to="/portfolio/website" className="App-nav__link" activeClassName="active">website</NavLink>
                    </li>
                    <li>
                      <NavLink to="/portfolio/webapp" className="App-nav__link" activeClassName="active">web app</NavLink>
                    </li>
                    <li>
                      <NavLink to="/portfolio/graphics" className="App-nav__link" activeClassName="active">graphics</NavLink>
                    </li>
                  </ul>
                </li>
                <li>
                  <NavLink to="/about" className="App-nav__link" activeClassName="active">about</NavLink>
                </li>
                <li>
                <NavLink to="/contact" className="App-nav__link App-nav__link--dropdown" activeClassName="active">contact</NavLink>
                  <ul className="App-nav__ul">
                    <li>
                      <a href="mailto:jenny@jenny-leung.com" className="App-nav__link">jenny@jenny-leung.com</a>
                    </li>
                    <li>
                      <a href="tel:7788910261" className="App-nav__link">(778) 891-0261</a>
                    </li>
                    <li>
                      <a href="https://www.linkedin.com/in/jenny-leung-32b5538a" target="new" className="App-nav__link">LinkedIn</a>
                    </li>
                  </ul>
                </li>
              </ul>
            </nav>
          </header>
          
          <Switch>
            <Route exact path="/" component={Home} />
            <Route path="/portfolio" component={Portfolio} />
            <Route path="/about" component={About} />
            <Route render={({location}) => (
              location.pathname === '/contact' ? (
                <div className="App-section App-content App-content--center">
                  <p>Thank you for visiting my portfolio. Please contact me for development opportunities.</p>
                </div>
              ) : (
                <Redirect to="/" />
              )
            )}/>
          </Switch>

          <Contact />
          <footer className="App-section App-footer">
            <p>&copy; {this.getYear()} Jenny Leung</p>
          </footer>
        </div>
      </BrowserRouter>
    );
  }
}

export default App;
